import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";

const Styledh2 = styled((props) => <h2 {...props} />)`
  color: ${constants.black};
  line-height: ${constants.H2Height};
  font-family: ${props => props.isenglish? constants.Dfont: constants.Cfont};
  font-size: ${constants.H2fontsize};
  margin-top: .7rem;
  margin-bottom: .7rem;

  @media screen and (max-width: 375px) {
  line-height: ${constants.H2HeightSP};
  font-size: ${constants.H2fontsizeSP};
  }
  @media screen and (min-width: 376px) and (max-width: 767px) {
  line-height: ${constants.H2HeightP};
  font-size: ${constants.H2fontsizeP};
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
  line-height: ${constants.H2HeightT};
  font-size: ${constants.H2fontsizeT};
  }
`;
export default function heading({ children, isenglish}) {
  return <Styledh2 isenglish ={isenglish}>{children}</Styledh2>;
}