import React from 'react'
import styled from 'styled-components'


const StyledDiv = styled(props=> <div {...props} />)`
    display: flex;
    justify-content: ${props => props.center? "center" : "space-around"};
    align-items: center;
    flex-direction: row;
  @media screen and (max-width: 1439px) {
    flex-direction: column;
    justify-content:flex-start;
    margin: auto;
    max-width:900px;
  }
`
export default function textcontainer({children, responsive, center, className}) {
    return (
        <StyledDiv responsive = {responsive} center={center} className={className}>
            {children}
        </StyledDiv>
    )
}
