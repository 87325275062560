import React from "react";
import styled from "styled-components";
import * as constants from "../style/constants";

const StyledP = styled((props) => <span {...props} />)`
  color: ${constants.black};
  line-height: ${constants.Content2Height};
  font-family: ${constants.Cfont};
  font-size: ${constants.P2fontsize};
  font-weight: ${props => props.bold? constants.P2BoldWeight : constants.P2RegWeight};
  text-align: justify;
  @media screen and (min-width: 425px) and (max-width: 1024px){
  color: ${constants.black};
  line-height: ${constants.P2fontheightP};
  font-family: ${constants.Cfont};
  font-size: ${constants.P2fontsizeP};
  font-weight: ${props => props.bold? constants.P2BoldWeight : constants.P2RegWeight};
  text-align: center;
  }
`;
export default function Paragaph({ children, bold, className}) {
  return <StyledP className={className} bold={bold}>{children}</StyledP>;
}