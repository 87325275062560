import React from "react";
import Img from "gatsby-image";
import * as constants from "../style/constants";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import UEh2 from "./smallheading";
import UEp from "./smalpp";
const StyledDiv = styled((props) => <div {...props} />)`
  @media screen and (max-width: 374px) {
    max-width: 310px;
    display: grid;
    align-items: start;
    justify-items: center;
    grid-template-rows: 210px auto auto;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 375px) and (max-width: 767px) {
    max-width: 95%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 280px auto auto;
    margin-bottom: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    max-width: 550px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 460px auto auto;
    margin-bottom: 25px;
  }
  @media screen and (min-width: 1024px) and (max-width: 1439px) {
    max-width: 75%;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 450px auto auto;
    margin-bottom: 25px;
  }
  @media screen and (min-width: 1440px) {
    max-width: 26%;
    grid-template-rows: 60% auto 180px;
  }
  @media screen and (min-width: 2100px) {
    max-width: 26%;
    grid-template-rows: 70% auto 180px;
  }
  max-width: 26%;
  display: grid;
  align-items: start;
  justify-items: center;
  grid-template-rows: 280px auto 180px;
`;
export default function Picturecard({
  children,
  src,
  title,
  width,
  height,
  className,
  isenglish,
  path,
}) {
  return (
    <StyledDiv className={className} isenglish={isenglish}>
      <Img
        fluid={src}
        style={{width:width, height:height}}
        imgstyle={{ width: '100%', height:'auto' }}
      />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <UEh2 isenglish={isenglish}>{title}</UEh2>
      </div>
      <UEp>{children}</UEp>
    </StyledDiv>
  );
}
